/* You can add global styles to this file, and also import other style files */
@import 'assets/fonts/ksfont';
@import 'styles/main';
@import 'styles/flowplayer_adapter';
@import 'media_queries';
@import 'styles/angular-notify';

[hidden] {
  display: none !important;
}

* {
  &:focus,
  &:hover {
    outline: none !important;
  }
}

$logoKs: url('./assets/img/kslogo.png');
$color_caret: #00c4cb;

.logo-ks {
  background: $logoKs no-repeat;
  height: 5em;
  margin: 2.8em 0 0 3.5em;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 5em;
  // left: 0;
  width: 15%;
  z-index: 100;
}

.player-ks {
  /* stylelint-disable property-no-vendor-prefix */
  .flowplayer .fp-waiting * {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  /* stylelint-enable property-no-vendor-prefix */
}

.vjs-text-track-display {
  display: none !important;
}

.vjs-poster {
  display: none !important;
}

.vjs-control-bar {
  display: none !important;
}

.vjs-modal-dialog {
  display: none !important;
}

.vjs-resize-manager {
  display: none !important;
}

.vjs-big-play-button {
  display: none !important;
}

.video-js .vjs-big-play-button {
  border: 0;
}

.vjs-loading-spinner {
  display: none !important;
}

.icon.icon-whatsapp {
  width: 1.6em;
  display: inline-block;
  height: 1.6em;
  background: url('./images/app/iconos/whatsapp.png');
}

.btn {
  font-size: 2.2em;
  border-radius: 3em;
  cursor: pointer;
  font-weight: bolder;
  width: 25em;
  height: 5.5em;
  letter-spacing: 0.1em;
}

.error-on-init {
  @include breakpointsForTV(2.5rem);

  color: white;
  margin: auto;
  width: 75%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .face-sad {
    font-size: 5em;
  }
}

.btn-retry-reload {
  @include breakpointsForTV(1rem);

  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10%;
  font-family: Montserrat;
  text-transform: uppercase;
  height: 4em;

  &:focus,
  &:hover {
    outline: none;
    border-color: $ksFocus;
  }

  &.focus-item-keyboard {
    border-color: $ksFocus !important;
  }
}

.caret-tv {
  border-left: 0.2em solid transparent;
  display: inline-block;
  position: fixed;

  &.void-input {
    animation: pulse-attention 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards infinite;
  }
}

@keyframes pulse-attention {
  50% {
    border-color: $color_caret;
  }
}
