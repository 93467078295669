@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/Montserrat/Montserrat-normal-400.eot);
  // prettier-ignore
  src:
    url(/assets/fonts/Montserrat/Montserrat-normal-400.ttf) format('truetype'),
    url(/assets/fonts/Montserrat/Montserrat-normal-400.svg#montserrat) format('svg'),
    url(/assets/fonts/Montserrat/Montserrat-normal-400.eot?#iefix) format('embedded-opentype'),
    url(/assets/fonts/Montserrat/Montserrat-normal-400.woff) format('woff'),
    url(/assets/fonts/Montserrat/Montserrat-normal-400.woff2) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  // prettier-ignore
  src:
    url(/assets/fonts/Montserrat/Montserrat-normal-600.ttf) format('truetype'),
    url(/assets/fonts/Montserrat/Montserrat-normal-600.woff) format('woff'),
    url(/assets/fonts/Montserrat/Montserrat-normal-600.woff2) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  // prettier-ignore
  src:
    url(/assets/fonts/Montserrat/Montserrat-normal-800.ttf) format('truetype'),
    url(/assets/fonts/Montserrat/Montserrat-normal-800.woff) format('woff'),
    url(/assets/fonts/Montserrat/Montserrat-normal-800.woff2) format('woff2');
}
